export const UNQUEUE_POOLS = {
	PRODUCTION: "prod",
	BETA: "beta",
	ALPHA: "alpha",
}

export const PAYMENT_METHODS = {
    CASH: "Cash",
    DEBIT_LINX: "Debit/Linx",
    CREDIT_CARD: "Credit Card"
}

export const PICKUP_METHODS = {
    IN_STORE: "Walkin",
    CURBSIDE: "Curbside",
    DELIVERY: "Delivery"
}

export const UNQUEUE_TIME_WINDOWS = {
    DAYTIME: '12pm and 6pm',
    EVENING: '5pm and 9pm'
}

export const SHOPPER_APP_STORE_URL =
    "https://apps.apple.com/tt/app/id1518497712"
export const SHOPPER_PLAY_STORE_URL =
    "https://play.google.com/store/apps/details?id=com.unqueue.customer"
export const BUSINESS_APP_STORE_URL =
    "https://apps.apple.com/tt/app/id1518488244"
export const BUSINESS_PLAY_STORE_URL =
    "https://play.google.com/store/apps/details?id=com.unqueue.business"